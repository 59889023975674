function formatDate(date) {
  try {
    var d = new Date(date);
    var datestring =
      ("0" + d.getDate()).slice(-2) +
      "/" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "/" +
      d.getFullYear();
    return datestring;
  } catch(e){
    return ""
  }
}

function formatDateStandard(date) {
  var d = new Date(date);
  var datestring =
    d.getFullYear() +
    "-" +
    ("0" + (d.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + d.getDate()).slice(-2)
  return datestring;
}

export default formatDate;
export {formatDateStandard};
