function convertTaxNumberToText(vat, is_no_tax = false) {
  if (is_no_tax && vat == 0) {
    return "KCT";
  } else if (vat == -1) {
    return "KKKNT";
  } else if (vat != null) {
    return vat + "%";
  }
  return "";
}

function getInvoiceStatus(invoice) {
  if (invoice) {
    if (invoice.lookupinfo__invoice_status == "1") {
      return "Hóa đơn mới";
    } else {
      try {
        return invoice.lookupinfo__invoice_note.match(/Trạng thái hóa đơn: ([^\\.]*)/)[1].replace(/(<([^>]+)>)/gi, "");
      } catch {
        return ""
      }
    }
  }
}

function durationReadAble(startAt, endAt) {
  let seconds = Math.floor((new Date(endAt) - new Date(startAt)) / 1000);
  var interval = seconds / 31536000;
  let result = ""

  if (interval > 1) {
    result += Math.floor(interval) + " năm ";
  }

  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    result += interval + " tháng ";
    seconds -= interval * 2592000
  }

  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    result += interval + " ngày ";
    seconds -= interval * 86400;
  }

  interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
    result += interval + " giờ ";
    seconds -= interval * 3600
  }

  interval = Math.floor(seconds / 60);
  if (interval >= 1) {
    result += interval + " phút ";
    seconds -= interval * 60
  }

  if (!result && seconds) {
    result += " < 1 phút";
  }
  return result
}

// Apply only for el-table
function styleChecker({ row, column }) {
  if (column.property == "report_period" && row['colors'] && row['colors'].length > 0) {
    const colors = row['colors'];
    return `
      background-repeat: no-repeat;
      background-size: 15px auto;
      background-image: ${buildColors(colors)};
    `
  }
}

function labelBackgroundStyle(colors) {
  return `
    background-repeat: no-repeat;
    background-size: 15px auto;
    background-image: ${buildColors(colors)};
  `
}

function buildColors(colors) {
  const smallestPart = parseInt(100 / colors.length);
  let result = `linear-gradient(to bottom, ${colors.at(0)} ${smallestPart}%`
  let i = 1;
  for(; i < (colors.length - 2) * 2 + 1; i++) {
    if (smallestPart * (i) < 95) {
      result += `, ${colors.at(i)} ${smallestPart * (i)}%`
    }
    if (smallestPart * (i+1) < 95) {
      result += `, ${colors.at(i)} ${smallestPart * (i + 1)}%`
    }
  }
  if (i == 1) {
    result += `, ${colors.at(colors.length-1)} ${smallestPart}%`;
  }
  result += `);`
  return result
}

function hashCode(obj) {
  obj = JSON.stringify(obj)
  var hash = 0, i, chr;
  if (obj.length === 0) return hash;
  for (i = 0; i < obj.length; i++) {
    chr = obj.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

export {
  convertTaxNumberToText,
  getInvoiceStatus,
  durationReadAble,
  buildColors,
  styleChecker,
  labelBackgroundStyle,
  hashCode,
};
