import Panzoom from "@panzoom/panzoom";
import formatDate from "@/plugins/formatDate.js";
import formatNumber from "@/plugins/formatNumber.js";

export default {
  name: "HtmlViewer",
  props: {
    invoice: {
      type: Object,
      default: {
          token_gov: {},
          products: [],
      },
    },
    isRequesting: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClickXML() {
      this.$emit("onDownloadXML");
    },
    getInvoiceTitle(form) {
      const formMapping = {
        1: "HÓA ĐƠN GIÁ TRỊ GIA TĂNG", // Fallback
        2: "HÓA ĐƠN BÁN HÀNG",
        3: "HÓA ĐƠN BÁN TÀI SẢN CÔNG",
        4: "HÓA ĐƠN BÁN HÀNG DỰ TRỮ QUỐC GIA",
        5: "HÓA ĐƠN KHÁC",
        6: "PHIẾU XUẤT KHO KIÊM VẬN CHUYỂN NỘI BỘ",
      };

      let title = formMapping[1];
      if (form && Object.keys(formMapping).indexOf(form.toString()) != -1) {
        title = formMapping[form];
      }
      return title;
    },
    zoom(level) {
      level === -1 ? this.zoomElement.zoomOut() : this.zoomElement.zoomIn();
    },
    wheel(level) {
      level === -1 ? this.zoomElement.zoomOut() : this.zoomElement.zoomIn();
    },
    formatDate,
    formatNumber,
  },

  mounted() {
    const ele = document.getElementById("html-viewer")
    this.zoomElement = Panzoom(ele, {
      maxScale: 5, animated: false
    });
    ele.parentElement.addEventListener('wheel', this.zoomElement.zoomWithWheel)
  },
};
